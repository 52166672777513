@charset "UTF-8";
/* webfont 変数　*/
/* ofi */
@media (min-width: 1825px) {
  /*====================================================================/
/*--Large PC 以上--
/====================================================================*/
}

@media (min-width: 1637px) {
  /*====================================================================/
/*---PC 以上--
/====================================================================*/
}

@media (min-width: 1057px) {
  /*====================================================================/
/*--XGAサイズ　iPadPro 以上--
/====================================================================*/
}

@media (min-width: 768px) {
  /*====================================================================/
/*--タブレット　iPadmini 以上--
/====================================================================*/
}

@media (min-width: 576px) {
  /*====================================================================/
/*--スマホ 以上--
/====================================================================*/
}

@media (min-width: 376px) {
  /*====================================================================/
/*--iPhone 以上--
/====================================================================*/
}

@media (max-width: 1824px) {
  /*====================================================================/
/*--Large PC 未満--
/====================================================================*/
}

@media (max-width: 1636px) {
  /*====================================================================/
/*--PC 未満--
/====================================================================*/
}

@media (max-width: 1056px) {
  /*====================================================================/
/*--XGAサイズ iPadPro 未満--
/====================================================================*/
}

@media (max-width: 767px) {
  /*====================================================================/
/*--タブレット iPadmini 未満--
/====================================================================*/
}

@media (max-width: 575px) {
  /*====================================================================/
/*--iPhone 以下--
/====================================================================*/
}

@media (min-width: 576px) and (max-width: 767px) {
  /*====================================================================/
/*--スマホ以上 タブレット iPadmini未満--
/====================================================================*/
}

@media (min-width: 768px) and (max-width: 1056px) {
  /*====================================================================/
/*--タブレット iPadmini以上 XGAサイズ iPadPro未満--
/====================================================================*/
}

@media (min-width: 1057px) and (max-width: 1636px) {
  /*====================================================================/
/*--XGAサイズ iPadPro以上 PC未満--
/====================================================================*/
}

@media (min-width: 1637px) and (max-width: 1824px) {
  /*====================================================================/
/*--px以上 Large PC未満--
/====================================================================*/
}

/*====================================================================

index.css

=====================================================================*/
/*--------------------------------------------------------------------/
	main-view
/--------------------------------------------------------------------*/
.main-view {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  background-repeat: no-repeat;
  background-position: center center;
}

@media (min-width: 768px) {
  .main-view {
    background-image: url(../img/top/top-main_tb.jpg);
    background-size: cover;
    height: 75vw;
  }
}

@media (min-width: 1057px) {
  .main-view {
    background-image: url(../img/top/top-main_pc.jpg);
    height: 842px;
  }
}

@media (max-width: 767px) {
  .main-view {
    background-image: url(../img/top/top-main_sp.jpg);
    background-size: cover;
    height: 125vw;
  }
}

.main-view__catch {
  position: absolute;
  right: 0;
  bottom: 0;
  background: #fff;
  width: 76.66%;
  height: 34vw;
}

@media (min-width: 768px) {
  .main-view__catch {
    width: 60%;
    height: 24.41vw;
  }
}

@media (min-width: 1057px) {
  .main-view__catch {
    width: 60%;
    min-width: 600px;
    height: 300px;
  }
}

@media (min-width: 1637px) {
  .main-view__catch {
    width: 48%;
  }
}

.main-view__catch__inner {
  width: 100%;
  height: 100%;
  position: relative;
  padding: 1rem 4% 1rem 8%;
  display: flex;
  justify-content: center;
  align-items: center;
}

@media (min-width: 768px) {
  .main-view__catch__inner {
    padding: 1rem 4% 1rem 10%;
  }
}

@media (min-width: 1057px) {
  .main-view__catch__inner {
    padding: 2rem 4%;
  }
}

.main-view__catch__add {
  position: absolute;
  right: 0;
  bottom: calc(100% - 1px);
  min-width: 200px;
  width: 60%;
}

.main-view__catch__tit {
  color: #000;
}

.main-view__catch__tit b {
  display: block;
  line-height: 1.2;
  font-size: calc(1rem + 30 * (100vw -  376px) / 1164);
}

@media (min-width: 768px) {
  .main-view__catch__tit b {
    font-size: calc(1.5rem + 28 * (100vw - 768px) / 772);
  }
}

@media (min-width: 1057px) {
  .main-view__catch__tit b {
    font-size: calc(2rem + 18 * (100vw - 1000px) / 540);
  }
}

@media (min-width: 1637px) {
  .main-view__catch__tit b {
    font-size: 3.13rem;
  }
}

.main-view__catch__tit .catch-txt01, .main-view__catch__tit .catch-txt02 {
  position: relative;
  display: block;
}

.main-view__catch__tit .catch-txt__inner {
  position: relative;
  display: inline-block;
  padding-top: 0.2em;
  padding-right: 0.05em;
  padding-bottom: 0;
  overflow: hidden;
}

.main-view__catch__tit .catch-txt__inner .letter {
  display: inline-block;
  line-height: 1em;
}

.main-view__catch__tit .catch-txt__small {
  display: block;
  line-height: 1.2;
  font-size: calc(0.8rem + 3 * (100vw -  376px) / 1164);
  margin-top: 1em;
  overflow: hidden;
}

@media (min-width: 768px) {
  .main-view__catch__tit .catch-txt__small {
    font-size: calc(0.8rem + 3 * (100vw - 768px) / 772);
  }
}

@media (min-width: 1637px) {
  .main-view__catch__tit .catch-txt__small {
    font-size: 1.19rem;
  }
}

.main-view__catch__tit .catch-txt__small span {
  display: inline-block;
}

/* common
----------------------------------------------------------------*/
/*--------------------------------------------------------------------/
	contents
/--------------------------------------------------------------------*/
.contents {
  overflow: hidden;
}

.top-news {
  padding: clamp(3rem, 9vw, 9rem) 0 clamp(4rem, 6vw, 6rem);
  margin-bottom: clamp(2rem, 6vw, 6rem);
  position: relative;
}

.top-news::before {
  position: absolute;
  content: "";
  background: #F3F5F7;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 45%;
  max-height: 450px;
  display: block;
}

.top-news__tit {
  margin-bottom: calc(2rem + 0.5em);
}

.top-news__tit em {
  display: inline-block;
  padding: 0 0.5em;
  font-family: 'Montserrat', sans-serif;
  letter-spacing: 0.1em;
  font-style: normal;
  display: block;
  font-weight: 700;
  position: relative;
  color: #000;
  line-height: 1.2;
  font-size: calc(2.5rem + 28 * (100vw -  376px) / 1164);
}

@media (min-width: 1637px) {
  .top-news__tit em {
    font-size: 4.5rem;
  }
}

.top-news__tit em::before {
  content: "";
  position: absolute;
  width: 50vw;
  height: 1px;
  top: 50%;
  right: 100%;
  background: #000;
}

.top-news__list {
  margin-bottom: 2rem;
}

.top-service {
  background: #CEDFEC;
  padding-top: clamp(3rem, 5vw, 5.5rem);
  padding-bottom: clamp(5rem, 9vw, 9rem);
  position: relative;
}

@media (min-width: 1057px) {
  .top-service {
    padding-top: 5.5rem;
  }
}

.top-service::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: calc(100% - 3.5rem);
  background: #1D2087;
}

@media (min-width: 1057px) {
  .top-service::before {
    height: calc(100% - 5.5rem);
  }
}

.top-service::after {
  content: "";
  position: absolute;
  bottom: 0;
  right: 0;
  background: #0E0F53;
  width: 90%;
  height: calc(100% - 4rem);
}

@media (min-width: 1057px) {
  .top-service::after {
    height: calc(100% - 7.5rem);
  }
}

@media (min-width: 1637px) {
  .top-service::after {
    width: calc(50% + 746px);
  }
}

.top-service__inner {
  position: relative;
  z-index: 2;
}

.top-service__tit {
  text-align: left;
  color: #fff;
  margin-bottom: clamp(1.5rem, 3vw, 3rem);
}

@media (min-width: 1057px) {
  .top-service__tit {
    margin-bottom: 4rem;
  }
}

.top-service__tit em {
  font-family: 'Montserrat', sans-serif;
  letter-spacing: 0.1em;
  font-style: normal;
  display: block;
  font-weight: 700;
  position: relative;
  line-height: 1.2;
  font-size: calc(1.5rem + 38 * (100vw -  376px) / 1164);
}

@media (min-width: 1637px) {
  .top-service__tit em {
    font-size: 3.75rem;
  }
}

.top-service__tit span {
  font-family: 'Noto Sans JP', sans-serif;
  letter-spacing: 0.1em;
  display: block;
  font-weight: 700;
  position: relative;
  line-height: 1.4;
  font-size: calc(1.14rem + 10 * (100vw -  376px) / 1164);
}

@media (min-width: 1637px) {
  .top-service__tit span {
    font-size: 1.75rem;
  }
}

.top-service__catch {
  display: inline-block;
  padding: 0.75em 1em;
  margin-bottom: calc(1rem + 2em);
  background: #fff;
  line-height: 1;
  width: 100vw;
  font-size: calc(1rem + 10 * (100vw -  376px) / 1164);
  font-weight: 700;
  color: #000;
}

@media (min-width: 1057px) {
  .top-service__catch {
    padding: 0.75em 2em;
  }
}

@media (min-width: 1637px) {
  .top-service__catch {
    font-size: 1.75rem;
  }
}

@media (min-width: 768px) {
  .top-service__list {
    display: flex;
    flex-wrap: wrap;
    column-gap: 2%;
    row-gap: 2rem;
  }
  .top-service__list > * {
    width: 49%;
  }
}

@media (min-width: 1057px) {
  .top-service__list {
    row-gap: clamp(2rem, 4vw, 4rem);
  }
}

@media (min-width: 1825px) {
  .top-service__list {
    column-gap: 4.62%;
  }
  .top-service__list > * {
    width: 47.69%;
  }
}

@media (max-width: 767px) {
  .top-service__list {
    display: flex;
    flex-direction: column;
    row-gap: clamp(2rem, 4vw, 4rem);
  }
}

.top-service__box a {
  transition: all 0.3s ease 0s;
  text-decoration: none !important;
  color: #232323;
  display: block;
  height: 100%;
}

@media (max-width: 1056px) {
  .top-service__box a {
    background: #fff;
  }
}

.top-service__box__inner {
  position: relative;
  min-height: 400px;
}

@media (min-width: 1057px) {
  .top-service__box__inner {
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
  }
}

@media (max-width: 1056px) {
  .top-service__box__inner {
    display: flex;
    flex-direction: column;
  }
}

.top-service__box__flex {
  margin-bottom: clamp(0.5rem, 1.5vw, 1.5rem);
  display: flex;
  column-gap: 0.5rem;
}

.top-service__box__flex span {
  line-height: 1;
  vertical-align: top;
}

.top-service__box__num {
  display: inline-block;
  background: #CE0000;
  color: #fff;
  padding: 0.4em 0.75em;
  font-family: 'Montserrat', sans-serif;
  font-weight: 700;
  letter-spacing: 0;
  font-size: 1.13rem;
  min-height: 36px;
}

.top-service__box__category {
  display: inline-block;
  min-height: 36px;
  padding: 0.4em 0.75em 0.3em;
  font-weight: 500;
  letter-spacing: 0;
  font-size: 1.13rem;
}

@media (min-width: 1057px) {
  .top-service__box__img {
    position: absolute;
    z-index: 1;
    top: 0;
    left: 0;
    width: 80%;
  }
}

@media (min-width: 1057px) {
  .top-service__box__img {
    width: 66.66%;
  }
}

.top-service__box__img__inner {
  position: relative;
  width: 100%;
}

.top-service__box__img__inner::before {
  content: "";
  display: block;
  padding-top: 64.28%;
  position: relative;
}

.top-service__box__img__inner > div,
.top-service__box__img__inner figure,
.top-service__box__img__inner a,
.top-service__box__img__inner picture {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
}

.top-service__box__img .is-gray {
  filter: grayscale(100%);
}

.top-service__box__txt {
  position: relative;
}

@media (min-width: 1057px) {
  .top-service__box__txt {
    z-index: 2;
    width: 80%;
    max-width: 375px;
  }
}

.top-service__box__txt__inner {
  padding: 1.5em 8% 2em;
}

@media (min-width: 1057px) {
  .top-service__box__txt__inner {
    padding: 2em 10% 2.5em;
    background: #fff;
  }
}

.top-service__box__tit {
  padding-bottom: clamp(0.5rem, 2vw, 1rem);
  margin-bottom: clamp(0.5rem, 2vw, 1rem);
  border-bottom: 1px solid #C9CED2;
  font-size: calc(1rem + 2 * (100vw -  376px) / 1164);
  color: #000;
  font-weight: 700;
}

@media (min-width: 1057px) {
  .top-service__box__tit {
    font-size: calc(1rem + 2 * (100vw - 1000px) / 540);
  }
}

@media (min-width: 1637px) {
  .top-service__box__tit {
    font-size: 1.13rem;
  }
}

.top-service__box__table {
  font-size: 0.88rem;
}

.top-service__box__table th {
  text-align: left;
  vertical-align: top;
  min-width: 115px;
  padding-right: 0.5em;
}

.top-list {
  background: #CEDFEC;
  padding-top: clamp(3rem, 9vw, 9rem);
  padding-bottom: clamp(5rem, 9vw, 9rem);
  position: relative;
  border-bottom: #1D2087 solid;
  border-width: 40px;
}

@media (min-width: 768px) {
  .top-list {
    border-width: 72px;
  }
}

.top-list::before {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 70%;
  background: #0E0F53;
}

.top-list__inner {
  position: relative;
  z-index: 2;
}

.top-list__up__list {
  display: flex;
}

@media (min-width: 768px) {
  .top-list__up__list {
    justify-content: space-between;
    row-gap: 3vw;
    flex-wrap: wrap;
  }
  .top-list__up__list > * {
    width: 31.25%;
  }
}

@media (min-width: 1825px) {
  .top-list__up__list {
    row-gap: 3.5rem;
  }
}

@media (max-width: 767px) {
  .top-list__up__list {
    flex-direction: column;
    row-gap: clamp(2rem, 3vw, 3rem);
  }
}

.top-list__bottom {
  margin-top: clamp(2rem, 3vw, 3rem);
}

@media (min-width: 768px) {
  .top-list__bottom {
    margin-top: 3.25vw;
  }
}

@media (min-width: 1825px) {
  .top-list__bottom {
    margin-top: 3.64rem;
  }
}

.top-list__bottom__list {
  display: flex;
}

@media (min-width: 768px) {
  .top-list__bottom__list {
    justify-content: space-between;
  }
  .top-list__bottom__list > * {
    width: 48.03%;
  }
}

@media (max-width: 767px) {
  .top-list__bottom__list {
    flex-direction: column;
    row-gap: clamp(2rem, 3vw, 3rem);
  }
}

.top-list__box.is-purchase .top-list__box__img::after {
  background: rgba(20, 20, 59, 0.65);
}

.top-list__box.is-purchase a:hover .top-list__box__img::after {
  background: rgba(20, 20, 59, 0.4);
}

.top-list__box.is-rental .top-list__box__img::after {
  background: rgba(20, 20, 59, 0.6);
}

.top-list__box.is-rental a:hover .top-list__box__img::after {
  background: rgba(20, 20, 59, 0.4);
}

.top-list__box.is-fixtures .top-list__box__img::after {
  background: rgba(20, 20, 59, 0.6);
}

.top-list__box.is-fixtures a:hover .top-list__box__img::after {
  background: rgba(20, 20, 59, 0.4);
}

.top-list__box.is-traning .top-list__box__img::after {
  background: rgba(23, 23, 133, 0.6);
}

.top-list__box.is-traning a:hover .top-list__box__img::after {
  background: rgba(23, 23, 133, 0.4);
}

.top-list__box.is-ecosuru .top-list__box__img::after {
  background: rgba(23, 23, 133, 0.6);
}

.top-list__box.is-ecosuru a:hover .top-list__box__img::after {
  background: rgba(23, 23, 133, 0.4);
}

.top-list__box.is-transmitter .top-list__box__img::after {
  background: rgba(20, 20, 59, 0.6);
}

.top-list__box.is-transmitter a:hover .top-list__box__img::after {
  background: rgba(20, 20, 59, 0.4);
}

.top-list__box a {
  display: block;
  height: 100%;
  position: relative;
  background: #fff;
  text-decoration: none !important;
  color: #232323;
  transition: all 0.3s ease 0s;
}

.top-list__box__main {
  position: relative;
}

.top-list__box__tit {
  position: absolute;
  top: 50%;
  left: 0;
  width: 100%;
  transform: translateY(-50%);
  z-index: 2;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
}

.top-list__box__tit span {
  text-align: center;
  line-height: 1;
}

.top-list__box__tit em {
  font-style: normal;
  font-weight: 700;
  font-family: 'Montserrat', sans-serif;
  display: block;
  font-size: calc(2rem + 16 * (100vw -  376px) / 1164);
}

@media (min-width: 768px) {
  .top-list__box__tit em {
    font-size: calc(1rem + 28 * (100vw - 768px) / 772);
  }
}

@media (min-width: 1637px) {
  .top-list__box__tit em {
    font-size: 3.42em;
  }
}

@media (min-width: 1637px) and (min-width: 576px) {
  .top-list__box__tit em {
    font-size: 3.2em;
  }
}

@media (min-width: 1637px) and (min-width: 1057px) {
  .top-list__box__tit em {
    font-size: 3em;
  }
}

.top-list__box__tit b {
  margin-top: 0.3em;
  font-weight: 700;
  display: block;
  font-size: calc(1rem + 6 * (100vw -  376px) / 1164);
}

@media (min-width: 768px) {
  .top-list__box__tit b {
    font-size: calc(0.84rem + 5 * (100vw - 768px) / 772);
  }
}

@media (min-width: 1637px) {
  .top-list__box__tit b {
    font-size: 1.42em;
  }
}

@media (min-width: 1637px) and (min-width: 576px) {
  .top-list__box__tit b {
    font-size: 1.33em;
  }
}

@media (min-width: 1637px) and (min-width: 1057px) {
  .top-list__box__tit b {
    font-size: 1.25em;
  }
}

.top-list__box__img {
  position: relative;
  width: 100%;
  z-index: 1;
}

.top-list__box__img::before {
  content: "";
  display: block;
  padding-top: 62.96%;
  position: relative;
}

.top-list__box__img > div,
.top-list__box__img figure,
.top-list__box__img a,
.top-list__box__img picture {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
}

.top-list__box__img::after {
  position: absolute;
  top: 0;
  left: 0;
  content: "";
  width: 100%;
  height: 100%;
  transition: all 0.3s ease 0s;
}

.top-list__box__txt {
  padding: 1.5em 5% 2em;
  font-size: 1.07em;
}

@media (min-width: 576px) {
  .top-list__box__txt {
    font-size: 1em;
  }
}

@media (min-width: 1057px) {
  .top-list__box__txt {
    font-size: 0.93em;
  }
}

@media (min-width: 768px) {
  .top-list__box__txt {
    font-size: calc(0.75rem + 3 * (100vw - 768px) / 772);
    padding: 1.5em 7% 2em;
  }
}

@media (min-width: 1637px) {
  .top-list__box__txt {
    font-size: 0.94rem;
  }
}

.top-list__box__txt ul {
  text-align: left;
}

.top-list__box__txt ul > li {
  line-height: 1.4;
  padding-left: 1.5rem;
  position: relative;
}

.top-list__box__txt ul > li::before {
  content: "●";
  color: #1D2087;
  position: absolute;
  left: 0;
  top: 0.2em;
  font-size: 0.8em;
}

.top-list__box__txt ul > li:not(:last-child) {
  margin-bottom: 0.5rem;
}

.top-list__box2 a {
  display: block;
  height: 100%;
  position: relative;
  background: #fff;
  text-decoration: none !important;
  color: #232323;
  transition: all 0.3s ease 0s;
}

@media (min-width: 576px) {
  .top-list__box2__inner {
    height: 100%;
    display: grid;
    grid-template-columns: 40% 60%;
    grid-template-rows: auto;
  }
}

@media (min-width: 576px) {
  .top-list__box2__img {
    grid-column: 1 / 2;
    grid-row: 1 / 2;
  }
}

@media (min-width: 576px) {
  .top-list__box2__img__inner {
    height: 100%;
  }
}

@media (max-width: 575px) {
  .top-list__box2__img__inner {
    position: relative;
    width: 100%;
  }
  .top-list__box2__img__inner::before {
    content: "";
    display: block;
    padding-top: 62.96%;
    position: relative;
  }
  .top-list__box2__img__inner > div,
  .top-list__box2__img__inner figure,
  .top-list__box2__img__inner a,
  .top-list__box2__img__inner picture {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
  }
}

@media (min-width: 576px) {
  .top-list__box2__img__inner > div {
    height: 100%;
  }
}

@media (min-width: 576px) {
  .top-list__box2__txt {
    grid-column: 2 / 3;
    grid-row: 1 / 2;
  }
}

.top-list__box2__txt__inner {
  padding: 1.5em 5% 2em;
}

@media (min-width: 576px) {
  .top-list__box2__txt__inner {
    padding: clamp(2em, 4vw, 3em) 8%;
  }
}

.top-list__box2__tit {
  margin-bottom: 1em;
}

.top-list__box2__tit em {
  font-style: normal;
  font-weight: 700;
  font-family: 'Montserrat', sans-serif;
  display: block;
  font-size: calc(2rem + 16 * (100vw -  376px) / 1164);
  line-height: 1;
  color: #1D2087;
}

@media (min-width: 576px) {
  .top-list__box2__tit em {
    font-size: calc(1.25rem + 24 * (100vw - 576px) / 964);
  }
}

@media (min-width: 1637px) {
  .top-list__box2__tit em {
    font-size: 2.85em;
  }
}

@media (min-width: 1637px) and (min-width: 576px) {
  .top-list__box2__tit em {
    font-size: 2.66em;
  }
}

@media (min-width: 1637px) and (min-width: 1057px) {
  .top-list__box2__tit em {
    font-size: 2.5em;
  }
}

@media (min-width: 576px) {
  .is-transmitter .top-list__box2__tit em {
    font-size: calc(0.94rem + 20 * (100vw - 576px) / 964);
  }
}

.top-list__box2__tit b {
  margin-top: 0.3em;
  font-weight: 700;
  display: block;
  font-size: calc(1rem + 2 * (100vw -  376px) / 1164);
}

@media (min-width: 576px) {
  .top-list__box2__tit b {
    font-size: calc(0.75rem + 3 * (100vw - 576px) / 964);
  }
}

@media (min-width: 1637px) {
  .top-list__box2__tit b {
    font-size: 1.28em;
  }
}

@media (min-width: 1637px) and (min-width: 576px) {
  .top-list__box2__tit b {
    font-size: 1.2em;
  }
}

@media (min-width: 1637px) and (min-width: 1057px) {
  .top-list__box2__tit b {
    font-size: 1.12em;
  }
}

@media (min-width: 768px) {
  .top-list__box2 .txt {
    font-size: calc(0.75rem + 3 * (100vw - 768px) / 772);
  }
}

@media (min-width: 1637px) {
  .top-list__box2 .txt {
    font-size: 0.94rem;
  }
}

.top-company {
  padding: clamp(6rem, 12vw, 12rem) 0 clamp(3rem, 12vw, 12rem);
}

@media (min-width: 768px) {
  .top-company {
    padding: clamp(3rem, 12vw, 12rem) 0;
  }
}

.top-company__inner {
  position: relative;
  display: flex;
}

@media (min-width: 768px) {
  .top-company__inner {
    align-items: flex-end;
  }
}

@media (max-width: 767px) {
  .top-company__inner {
    flex-direction: column;
    row-gap: 2rem;
  }
}

.top-company__bg {
  position: absolute;
  top: -2rem;
  z-index: -1;
  width: clamp(200px, 54.54%, 850px);
}

@media (min-width: 768px) {
  .top-company__bg {
    top: -3rem;
    left: 20%;
  }
}

@media (min-width: 1637px) {
  .top-company__bg {
    left: 7%;
  }
}

@media (max-width: 767px) {
  .top-company__bg {
    right: -5%;
  }
}

@media (min-width: 768px) {
  .top-company__txt {
    width: 50%;
    height: 100%;
  }
}

@media (min-width: 1637px) {
  .top-company__txt {
    width: 40.25%;
  }
}

@media (min-width: 768px) {
  .top-company__txt__inner {
    padding-right: 20%;
  }
}

.top-company__txt .btn-wrap {
  margin-top: clamp(1.5rem, 3vw, 4rem);
}

@media (min-width: 768px) {
  .top-company__txt .btn-wrap {
    max-width: 320px;
  }
}

.top-company__tit {
  margin-bottom: calc(2rem + 0.5em);
}

.top-company__tit em {
  font-family: 'Montserrat', sans-serif;
  letter-spacing: 0.1em;
  font-style: normal;
  display: block;
  color: #000;
  font-weight: 700;
  position: relative;
  line-height: 1.2;
  font-size: calc(2rem + 28 * (100vw -  376px) / 1164);
}

@media (min-width: 1637px) {
  .top-company__tit em {
    font-size: 3.75rem;
  }
}

.top-company__tit span {
  font-family: 'Noto Sans JP', sans-serif;
  letter-spacing: 0.1em;
  display: block;
  font-weight: 700;
  position: relative;
  line-height: 1.4;
  font-size: calc(1.14rem + 10 * (100vw -  376px) / 1164);
}

@media (min-width: 1637px) {
  .top-company__tit span {
    font-size: 1.75rem;
  }
}

@media (min-width: 768px) {
  .top-company__img {
    width: 50%;
    height: 100%;
  }
}

@media (min-width: 1637px) {
  .top-company__img {
    width: 59.75%;
  }
}

@media (min-width: 768px) {
  .top-company__img__inner {
    height: 100%;
  }
}

@media (max-width: 767px) {
  .top-company__img__inner {
    position: relative;
    width: 100%;
  }
  .top-company__img__inner::before {
    content: "";
    display: block;
    padding-top: 50%;
    position: relative;
  }
  .top-company__img__inner > div,
  .top-company__img__inner figure,
  .top-company__img__inner a,
  .top-company__img__inner picture {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
  }
}

@charset "utf-8";
@import "base";
/*====================================================================

index.css

=====================================================================*/

/*--------------------------------------------------------------------/
	main-view
/--------------------------------------------------------------------*/
.main-view {
	position: relative;
	display: flex;
	align-items: center;
	justify-content: center;
	background-repeat: no-repeat;
	background-position: center center;
	@include media-breakpoint-up(md) {
		background-image: url(../img/top/top-main_tb.jpg);
		background-size: cover;
		height: 75vw;
	}
	@include media-breakpoint-up(lg) {
		background-image: url(../img/top/top-main_pc.jpg);
		height: 842px;
	}
	@include media-breakpoint-down(sm) {
		background-image: url(../img/top/top-main_sp.jpg);
		background-size: cover;
		height: 125vw;
	}
	&__catch {
		position: absolute;
		right: 0;
		bottom: 0;
		background: $white;
		width: 76.66%;
		height: 34vw;
		@include media-breakpoint-up(md) {
			width: 60%;
			height: 24.41vw;
		}
		@include media-breakpoint-up(lg) {
			width: 60%;
			min-width: 600px;
			height: 300px;
		}
		@include media-breakpoint-up(xl) {
			width: 48%;
		}
		&__inner {
			width: 100%;
			height: 100%;
			position: relative;
			padding: 1rem 4% 1rem 8%;
			display: flex;
			justify-content: center;
			align-items: center;
			@include media-breakpoint-up(md) {
				padding: 1rem 4% 1rem 10%;
			}
			@include media-breakpoint-up(lg) {
				padding: 2rem 4%;
			}
		}
		&__add {
			position: absolute;
			right: 0;
			bottom: calc(100% - 1px);
			min-width: 200px;
			width: 60%;
		}
		&__tit {
			color: $black;
			b {
				display: block;
				@include line-h(1.2);
				@include f-s_xs(1, 30);
				@include media-breakpoint-up(md) {
					@include f-s_md(1.5, 28);
				}
				@include media-breakpoint-up(lg) {
					@include f-s_lg(2, 18);
				}
				@include media-breakpoint-up(xl) {
					@include f-size(50);
				}
			}
			.catch-txt {
				&01,
				&02 {
					position: relative;
					display: block;
				}
				&__inner {
					position: relative;
					display: inline-block;
					padding-top: 0.2em;
					padding-right: 0.05em;
					padding-bottom: 0;
					overflow: hidden;
					.letter {
						display: inline-block;
						line-height: 1em;
					}
				}
				&__small {
					display: block;
					@include line-h(1.2);
					@include f-s_xs(0.8, 3);
					margin-top: 1em;
					overflow: hidden;
					@include media-breakpoint-up(md) {
						@include f-s_md(0.8, 3);
					}
					@include media-breakpoint-up(xl) {
						@include f-size(19);
					}
					span {
						display: inline-block;
					}
				}
			}
		}
	}
}

/* common
----------------------------------------------------------------*/
/*--------------------------------------------------------------------/
	contents
/--------------------------------------------------------------------*/
.contents {
	overflow: hidden;
}

// top-news
.top-news {
	padding: clamp(3rem, 9vw, 9rem) 0 clamp(4rem, 6vw, 6rem);
	margin-bottom: clamp(2rem, 6vw, 6rem);
	position: relative;
	&::before {
		position: absolute;
		content: "";
		background: $p-gray;
		left: 0;
		bottom: 0;
		width: 100%;
		height: 45%;
		max-height: 450px;
		display: block;
	}
	&__tit {
		margin-bottom: calc(2rem + 0.5em);
		em {
			display: inline-block;
			padding: 0 0.5em;
			@include f-family(font02);
			@include l-sp(0.1em);
			font-style: normal;
			display: block;
			@include f-w(700);
			position: relative;
			color: $black;
			line-height: 1.2;
			@include f-s_xs(2.5, 28);
			@include media-breakpoint-up(xl) {
				@include f-size(72);
			}
			&::before {
				content: "";
				position: absolute;
				width: 50vw;
				height: 1px;
				top: 50%;
				right: 100%;
				background: $black;
			}
		}
	}
	&__list {
		margin-bottom: 2rem;
	}
}

// top-service
.top-service {
	background: $other_c2;
	padding-top: clamp(3rem, 5vw, 5.5rem);
	padding-bottom: clamp(5rem, 9vw, 9rem);
	position: relative;
	@include media-breakpoint-up(lg) {
		padding-top: 5.5rem;
	}
	&::before {
		content: "";
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: calc(100% - 3.5rem);
		background: $main_c;
		@include media-breakpoint-up(lg) {
			height: calc(100% - 5.5rem);
		}
	}
	&::after {
		content: "";
		position: absolute;
		bottom: 0;
		right: 0;
		background: $sub_c;
		width: 90%;
		height: calc(100% - 4rem);
		@include media-breakpoint-up(lg) {
			height: calc(100% - 7.5rem);
		}
		@include media-breakpoint-up(xl) {
			width: calc(50% + 746px);
		}
	}
	&__inner {
		position: relative;
		z-index: z-index(module, part03);
	}
	&__tit {
		text-align: left;
		color: $white;
		margin-bottom: clamp(1.5rem, 3vw, 3rem);
		@include media-breakpoint-up(lg) {
			margin-bottom: 4rem;
		}
		em {
			@include f-family(font02);
			@include l-sp(0.1em);
			font-style: normal;
			display: block;
			@include f-w(700);
			position: relative;
			line-height: 1.2;
			@include f-s_xs(1.5, 38);
			@include media-breakpoint-up(xl) {
				@include f-size(60);
			}
		}
		span {
			@include f-family(font01);
			@include l-sp(0.1em);
			display: block;
			@include f-w(700);
			position: relative;
			line-height: 1.4;
			@include f-s_xs(1.14, 10);
			@include media-breakpoint-up(xl) {
				@include f-size(28);
			}
		}
	}
	&__catch {
		display: inline-block;
		padding: 0.75em 1em;
		margin-bottom: calc(1rem + 2em);
		background: $white;
		@include line-h(1);
		width: 100vw;
		@include f-s_xs(1, 10);
		@include f-w(700);
		color: $black;
		@include media-breakpoint-up(lg) {
			padding: 0.75em 2em;
		}
		@include media-breakpoint-up(xl) {
			@include f-size(28);
		}
	}
	&__list {
		@include media-breakpoint-up(md) {
			@include flex-wrap;
			column-gap: 2%;
			row-gap: 2rem;
			& > * {
				width: 49%;
			}
		}
		@include media-breakpoint-up(lg) {
			row-gap: clamp(2rem, 4vw, 4rem);
		}
		@include media-breakpoint-up(xxl) {
			column-gap: 4.62%;
			& > * {
				width: 47.69%;
			}
		}
		@include media-breakpoint-down(sm) {
			@include flex-column;
			row-gap: clamp(2rem, 4vw, 4rem);
		}
	}
}

.top-service__box {
	a {
		@include transition;
		@include dec-none;
		color: $txt_c;

		display: block;
		height: 100%;
		@include media-breakpoint-down(md) {
			background: $white;
		}
	}
	&__inner {
		position: relative;
		min-height: 400px;
		@include media-breakpoint-up(lg) {
			@include flex-end;
			align-items: flex-end;
		}
		@include media-breakpoint-down(md) {
			@include flex-column;
		}
	}
	&__flex {
		margin-bottom: clamp(0.5rem, 1.5vw, 1.5rem);
		display: flex;
		column-gap: 0.5rem;
		span {
			@include line-h(1);
			vertical-align: top;
		}
	}
	&__num {
		display: inline-block;
		background: $red;
		color: $white;
		padding: 0.4em 0.75em;
		@include f-family(font02);
		@include f-w(700);
		@include l-sp(0);
		@include f-size(18);
		min-height: 36px;
	}
	&__category {
		display: inline-block;
		min-height: 36px;
		padding: 0.4em 0.75em 0.3em;
		@include f-w(500);
		@include l-sp(0);
		@include f-size(18);
	}
	&__img {
		@include media-breakpoint-up(lg) {
			position: absolute;
			z-index: z-index(module, part04);
			top: 0;
			left: 0;
			width: 80%;
		}
		@include media-breakpoint-up(lg) {
			width: 66.66%;
		}
		&__inner {
			@include aspect-img(14, 9);
		}
		.is-gray {
			filter: grayscale(100%);
		}
	}
	&__txt {
		position: relative;
		@include media-breakpoint-up(lg) {
			z-index: z-index(module, part03);
			width: 80%;
			max-width: 375px;
		}
		&__inner {
			padding: 1.5em 8% 2em;
			@include media-breakpoint-up(lg) {
				padding: 2em 10% 2.5em;
				background: $white;
			}
		}
	}
	&__tit {
		padding-bottom: clamp(0.5rem, 2vw, 1rem);
		margin-bottom: clamp(0.5rem, 2vw, 1rem);
		border-bottom: 1px solid $m-gray;
		@include f-s_xs(1, 2);
		color: $black;
		@include f-w(700);
		@include media-breakpoint-up(lg) {
			@include f-s_lg(1, 2);
		}
		@include media-breakpoint-up(xl) {
			@include f-size(18);
		}
	}
	&__table {
		@include f-size(14);
		th {
			text-align: left;
			vertical-align: top;
			min-width: 115px;
			padding-right: 0.5em;
		}
	}
}

// top-list
.top-list {
	background: $other_c2;
	padding-top: clamp(3rem, 9vw, 9rem);
	padding-bottom: clamp(5rem, 9vw, 9rem);
	position: relative;
	border-bottom: $main_c solid;
	border-width: 40px;
	@include media-breakpoint-up(md) {
		border-width: 72px;
	}
	&::before {
		content: "";
		position: absolute;
		bottom: 0;
		left: 0;
		width: 100%;
		height: 70%;
		background: $sub_c;
	}

	&__inner {
		position: relative;
		z-index: z-index(module, part03);
	}
	&__up {
		&__list {
			display: flex;
			@include media-breakpoint-up(md) {
				justify-content: space-between;
				row-gap: 3vw;
				flex-wrap: wrap;
				& > * {
					width: 31.25%;
				}
			}
			@include media-breakpoint-up(xxl) {
				row-gap:3.5rem;
			}
			@include media-breakpoint-down(sm) {
				flex-direction: column;
				row-gap: clamp(2rem, 3vw, 3rem);
			}
		}
	}
	&__bottom {
		margin-top: clamp(2rem, 3vw, 3rem);
		@include media-breakpoint-up(md) {
			margin-top: 3.25vw;
		}
		@include media-breakpoint-up(xxl) {
			margin-top: 3.64rem;
		}
		&__list {
			display: flex;
			@include media-breakpoint-up(md) {
				justify-content: space-between;
				& > * {
					width: 48.03%;
				}
			}
			@include media-breakpoint-down(sm) {
				flex-direction: column;
				row-gap: clamp(2rem, 3vw, 3rem);
			}
		}
	}
}

.top-list__box {
	&.is-purchase {
		.top-list__box__img::after {
			background: rgba(#14143b, 0.65);
		}
		a:hover {
			.top-list__box__img::after {
				background: rgba(#14143b, 0.4);
			}
		}
	}
	&.is-rental {
		.top-list__box__img::after {
			background: rgba(#14143b, 0.6);
		}
		a:hover {
			.top-list__box__img::after {
				background: rgba(#14143b, 0.4);
			}
		}
	}
	&.is-fixtures {
		.top-list__box__img::after {
			background: rgba(#14143b, 0.6);
		}
		a:hover {
			.top-list__box__img::after {
				background: rgba(#14143b, 0.4);
			}
		}
	}
	&.is-traning {
		.top-list__box__img::after {
			background: rgba(#171785, 0.6);
		}
		a:hover {
			.top-list__box__img::after {
				background: rgba(#171785, 0.4);
			}
		}
	}
	&.is-ecosuru {
		.top-list__box__img::after {
			background: rgba(#171785, 0.6);
		}
		a:hover {
			.top-list__box__img::after {
				background: rgba(#171785, 0.4);
			}
		}
	}
	&.is-transmitter {
		.top-list__box__img::after {
			background: rgba(#14143b, 0.6);
		}
		a:hover {
			.top-list__box__img::after {
				background: rgba(#14143b, 0.4);
			}
		}
	}
	a {
		display: block;
		height: 100%;
		position: relative;
		background: $white;
		@include dec-none;
		color: $txt_c;
		@include transition;
	}
	&__main {
		position: relative;
	}
	&__tit {
		position: absolute;
		top: 50%;
		left: 0;
		width: 100%;
		transform: translateY(-50%);
		z-index: z-index(module, part03);
		color: $white;
		display: flex;
		align-items: center;
		justify-content: center;
		span {
			text-align: center;
			@include line-h(1);
		}
		em {
			font-style: normal;
			@include f-w(700);
			@include f-family(font02);
			display: block;
			@include f-s_xs(2, 16);
			@include media-breakpoint-up(md) {
				@include f-s_md(1, 28);
			}
			@include media-breakpoint-up(xl) {
				@include f-em(48);
			}
		}
		b {
			margin-top: 0.3em;
			@include f-w(700);
			display: block;
			@include f-s_xs(1, 6);
			@include media-breakpoint-up(md) {
				@include f-s_md(0.84, 5);
			}
			@include media-breakpoint-up(xl) {
				@include f-em(20);
			}
		}
	}
	&__img {
		@include aspect-img(27, 17);
		z-index: z-index(module, part04);
		&::after {
			position: absolute;
			top: 0;
			left: 0;
			content: "";
			width: 100%;
			height: 100%;
			@include transition;
		}
	}
	&__txt {
		padding: 1.5em 5% 2em;
		@include f-em(15);
		@include media-breakpoint-up(md) {
			@include f-s_md(0.75, 3);
			padding: 1.5em 7% 2em;
		}
		@include media-breakpoint-up(xl) {
			@include f-size(15);
		}
		ul {
			text-align: left;
			& > li {
				line-height: 1.4;
				padding-left: 1.5rem;
				position: relative;
				&::before {
					content: "●";
					color: $main_c;
					position: absolute;
					left: 0;
					top: 0.2em;
					font-size: 0.8em;
				}
				&:not(:last-child) {
					margin-bottom: 0.5rem;
				}
			}
		}
	}
}

.top-list__box2 {
	a {
		display: block;
		height: 100%;
		position: relative;
		background: $white;
		@include dec-none;
		color: $txt_c;
		@include transition;
	}
	&__inner {
		@include media-breakpoint-up(sm) {
			height: 100%;
			display: grid;
			grid-template-columns: 40% 60%;
			grid-template-rows: auto;
		}
	}
	&__img {
		@include media-breakpoint-up(sm) {
			grid-column: 1 / 2;
			grid-row: 1 / 2;
		}
		&__inner {
			@include media-breakpoint-up(sm) {
				height: 100%;
			}
			@include media-breakpoint-down(xs) {
				@include aspect-img(27, 17);
			}
			& > div {
				@include media-breakpoint-up(sm) {
					height: 100%;
				}
			}
		}
	}
	&__txt {
		@include media-breakpoint-up(sm) {
			grid-column: 2 / 3;
			grid-row: 1 / 2;
		}
		&__inner {
			padding: 1.5em 5% 2em;
			@include media-breakpoint-up(sm) {
				padding: clamp(2em, 4vw, 3em) 8%;
			}
		}
	}
	&__tit {
		margin-bottom: 1em;
		em {
			font-style: normal;
			@include f-w(700);
			@include f-family(font02);
			display: block;
			@include f-s_xs(2, 16);
			@include line-h(1);
			color: $main_c;
			@include media-breakpoint-up(sm) {
				@include f-s_sm(1.25, 24);
			}
			@include media-breakpoint-up(xl) {
				@include f-em(40);
			}
			.is-transmitter & {
				@include media-breakpoint-up(sm) {
					@include f-s_sm(0.94, 20);
				}
			}
		}
		b {
			margin-top: 0.3em;
			@include f-w(700);
			display: block;
			@include f-s_xs(1, 2);
			@include media-breakpoint-up(sm) {
				@include f-s_sm(0.75, 3);
			}
			@include media-breakpoint-up(xl) {
				@include f-em(18);
			}
		}
	}
	.txt {
		@include media-breakpoint-up(md) {
			@include f-s_md(0.75, 3);
		}
		@include media-breakpoint-up(xl) {
			@include f-size(15);
		}
	}
}

// top-company
.top-company {
	padding: clamp(6rem, 12vw, 12rem) 0 clamp(3rem, 12vw, 12rem);
	@include media-breakpoint-up(md) {
		padding: clamp(3rem, 12vw, 12rem) 0;
	}
	&__inner {
		position: relative;
		display: flex;
		@include media-breakpoint-up(md) {
			align-items: flex-end;
		}
		@include media-breakpoint-down(sm) {
			flex-direction: column;
			row-gap: 2rem;
		}
	}
	&__bg {
		position: absolute;
		top: -2rem;
		z-index: -1;
		width: clamp(200px, 54.54%, 850px);
		@include media-breakpoint-up(md) {
			top: -3rem;
			left: 20%;
		}
		@include media-breakpoint-up(xl) {
			left: 7%;
		}
		@include media-breakpoint-down(sm) {
			right: -5%;
		}
	}
	&__txt {
		@include media-breakpoint-up(md) {
			width: 50%;
			height: 100%;
		}
		@include media-breakpoint-up(xl) {
			width: 40.25%;
		}
		&__inner {
			@include media-breakpoint-up(md) {
				padding-right: 20%;
			}
		}
		.btn-wrap {
			margin-top: clamp(1.5rem, 3vw, 4rem);
			@include media-breakpoint-up(md) {
				max-width: 320px;
			}
		}
	}
	&__tit {
		margin-bottom: calc(2rem + 0.5em);
		em {
			@include f-family(font02);
			@include l-sp(0.1em);
			font-style: normal;
			display: block;
			color: $black;
			@include f-w(700);
			position: relative;
			line-height: 1.2;
			@include f-s_xs(2, 28);
			@include media-breakpoint-up(xl) {
				@include f-size(60);
			}
		}
		span {
			@include f-family(font01);
			@include l-sp(0.1em);
			display: block;
			@include f-w(700);
			position: relative;
			line-height: 1.4;
			@include f-s_xs(1.14, 10);
			@include media-breakpoint-up(xl) {
				@include f-size(28);
			}
		}
	}
	&__img {
		@include media-breakpoint-up(md) {
			width: 50%;
			height: 100%;
		}
		@include media-breakpoint-up(xl) {
			width: 59.75%;
		}
		&__inner {
			@include media-breakpoint-up(md) {
				height: 100%;
			}
			@include media-breakpoint-down(sm) {
				@include aspect-img(2, 1);
			}
		}
	}
}
